<template>
    <div class="content-wrap">
        <div class="content-main">
            <div class="personal-header">
                <div class="header-title">
                    <span>个人中心</span>
                </div>
            </div>
            <div class="personal-content">
                <div class="content-top">
                    <div class="top-text">
                        <label>账户名：</label>
                        <span>{{user.username}}</span>
                    </div>
                    <div class="top-button" v-if="false">
                        <el-button @click="changePasswordVisible = true">修改密码</el-button>
                        <el-button type="primary" @click="handleGetAuthorizationCode">获取授权码</el-button>
                    </div>
                </div>
                <div class="content-bottom">
                    <div class="bottom-title">个人信息</div>
                    <div class="bottom-body">
                        <label-span label="手机号:" :span="user.phone"/>
                        <label-span label="单位名称:" :span="user.tenantName"/>
                    </div>
                </div>
            </div>

            <!-- 修改密码弹窗 -->
            <el-dialog append-to-body title="修改密码"
                       width="25%"
                       :visible.sync="changePasswordVisible"
                       @close="handleChangePasswordCancel">
                <div class="dialog-body">
                    <el-input type="text" v-model="user.password" placeholder="输入旧密码"/>
                    <el-input type="text" placeholder="输入新密码"/>
                    <el-input type="text" placeholder="输入确认密码"/>
                    <el-button type="text">忘记旧密码</el-button>
                </div>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="changePasswordVisible = false">取消</el-button>
                    <el-button type="primary" @click="handleChangePasswordConfirm">确认修改</el-button>
                </span>
            </el-dialog>
            <!-- 获取授权码弹窗 -->
        </div>
    </div>
</template>

<script>
    import {
        getPersonalCenterUserInfo
    } from "@/service/user"

    export default {
        name: "Own",
        data() {
            return {
                user: {
                    username: '',
                    phone: '',
                    password: '',
                    passwordNew: '',
                    tenantName: ''
                },
                changePasswordVisible: false
            }
        },
        created() {
            this.handleGetPersonalCenterData()
        },
        methods: {
            /* 个人中心信息 */
            handleGetPersonalCenterData() {
                getPersonalCenterUserInfo().then(res => {
                    if (res.code == 1) {
                        this.user = res.data;
                    }
                })
            },
            /* 修改密码确认 */
            handleChangePasswordConfirm() {
            },
            /* 修改密码取消 */
            handleChangePasswordCancel() {
                console.log("修改密码取消了..");
                this.changePasswordVisible = false;
            },
            /*  获取授权码 */
            handleGetAuthorizationCode() {
            }
        }
    }
</script>
<style lang="scss" scoped>
    .content-main {
        height: calc(100% - 32px);

        .personal-header {
            display: flex;
            justify-content: space-between;
            padding-bottom: 16px;
            border-bottom: 1px solid #e9e9e9;

            .header-title {
                font-size: 16px;
                font-weight: 500;
                color: #333;

                span:before {
                    content: "|";
                    display: inline-block;
                    margin-right: 10px;
                    background: #333333;
                }
            }
        }

        .personal-content {
            .content-top {
                display: flex;
                padding-top: 24px;

                .top-text {
                    margin-right: 64px;

                    label {
                        width: 96px;
                        height: 33px;
                        font-size: 24px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #333333;
                        line-height: 33px;
                    }

                    span {
                        width: 192px;
                        height: 33px;
                        font-size: 24px;
                        font-family: PingFangSC-Medium, PingFang SC;
                        font-weight: 500;
                        color: #333333;
                        line-height: 33px;
                    }
                }
            }

            .content-bottom {
                height: 232px;
                margin-top: 24px;
                border-radius: 2px;
                border: 1px solid rgba(0, 0, 0, 0.15);
                padding: 24px;

                .bottom-title {
                    width: 64px;
                    height: 24px;
                    font-size: 16px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #333333;
                    line-height: 24px;
                    margin-bottom: 20px;
                }

                .bottom-body {
                    display: flex;

                    .label-span {
                        margin-right: 54px;
                    }
                }
            }
        }
    }

    .dialog-body {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding: 0 24px;
        margin-top: -10px;

        .el-input {
            width: 100%;
            margin-bottom: 16px;
        }

        .el-button {
            padding: 0;
            width: 70px;
            height: 0px;
            text-align: left;
        }
    }

    .dialog-footer {
        height: 32px;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        margin-top: 10px;

        .el-button {
            padding: 0;
            width: 88px;
            line-height: 30px;
        }
    }
</style>
